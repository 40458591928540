import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  color: white;
  img {
    cursor: pointer;
    width: 200px;
    height: 80px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 4px;

    img {
      margin-top: 0;
      max-width: 95%;
    }

    .logo-title  {
      .logo-text  {
        font-size: 22px;
        line-height: 22px;
      }

      .logo-subtext  {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

const Logo = () => {

  return (
    <StyledLogo>
      <img
        src={`${publicRuntimeConfig.basePath}/images/V2/V2-fileforein/logo.png`}
        alt="Logo"
      />
    </StyledLogo>
  );
};

export default Logo;
