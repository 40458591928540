import Link from "next/link";
import LinkButton from "../../components/V2-reflection-4/styled/LinkButton";

export const PRODUCTS = [
  {
    code: "EIN_LLC",
    path: "EINLLC",
    iconSrc: 'images/V2/V2-reflection-2/llc-icon-blue.svg',
    category: "EIN",
    customerName: "Limited Liability Company (LLC)",
    shortDescription: "You have already setup a legal LLC entity",
    detailedDescription: (
      <>
        <span>
          A Limited Liability Company is formed by filing articles of organization with the individual state’s Secretary of State. 
          Owners of an LLC are called members. Members may include individuals, corporations, other LLCs, and foreign entities. 
          An LLC can be formed by one or more members, and there is no maximum number of members.
        </span>
        <br />
        <br />
        <span>
          LLCs are generally the preferred entity structure for certain professionals and landlord as it is a corporate structure where the members (owners) cannot be held personally liable for the company&apos;s liabilities.
          LLCs have flexibility as the owners can file as a partnership, S Corporation or even sole proprietor since the LLC is really a legal and not tax designation.
        </span>
        <br />
        <br />
        <Link href="/products/EINLLC" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: true,
  },
  {
    code: "EIN_SoleProp",
    path: "EINSoleProp",
    iconSrc: 'images/V2/V2-reflection-2/solo-icon-blue.svg',
    category: "EIN",
    customerName: "Sole Proprietor / Individual",
    shortDescription: "You have no employees AND have NOT filed incorporation documentation with your state",
    detailedDescription: (
      <>
        <span>
          A Sole Proprietor is one individual who owns a company that is not incorporated or registered with the state as a limited liability company (LLC) or Corporation. 
          This includes everyone who is a freelancer, independent contractor and many business owners who don’t have partners. 
          Household employers are also included in this category.
        </span>
        <br />
        <br />
        <Link href="/products/EINSoleProp" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: true,
  },
  {
    code: "EIN_Estate",
    path: "EINEstate",
    iconSrc: 'images/V2/V2-reflection-2/estate-icon-blue.svg',
    category: "EIN",
    customerName: "Estate of Deceased Individual",
    shortDescription: "Your legal entity was created as a result of a person's death",
    detailedDescription: (
      <>
        <span>
          An Estate is a legal entity created as a result of a person&apos;s death.
          The Estate consists of the personal property and/or real estate of the deceased person. 
          The Estate is responsible for paying any debts owed by the decedent, and distributing the balance of the estate&apos;s assets to the beneficiaries.
        </span>
        <br />
        <br />
        <Link href="/products/EINEstate" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_Trust",
    path: "EINTrust",
    iconSrc: 'images/V2/V2-reflection-2/trust-icon-blue.svg',
    category: "EIN",
    customerName: "Trusts",
    shortDescription: "A trust is a legal entity in which one party holds assets for the benefit of another",
    detailedDescription: (
      <>
        <span>
          A Trust is a legal entity created under state law and is a fiduciary relationship in which one party, 
          known as a Trustor, gives another party, known at the Trustee, 
          the right to legal ownership of assets (including property) for the benefit of another.
        </span>
        <br />
        <br />
        <span>
          The most common types of Trusts are Irrevocable and Revocable Trusts:
        </span>
        <br />
        <br />
        <ul>
          <li>
            Irrevocable Trust – The Trustor has no control of the trust (the trust cannot be repealed or annulled) and the trust will pay tax.
          </li>
          <li>
            Revocable Trust – A revocable trust is a trust that may be altered or terminated during the Trustor’s lifetime. 
            Since the trust may be altered at any time until the Trustor’s death, it is considered part of the Trustor’s estate and is subject to taxation.
          </li>
        </ul>
        <br />
        <Link href="/products/EINTrust" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_NonProfit",
    path: "EINNonProfit",
    iconSrc: 'images/V2/V2-reflection-2/non-profit-icon-blue.svg',
    category: "EIN",
    customerName: "Non-Profit Organization",
    shortDescription: "Your organization qualifies for tax-exempt status",
    detailedDescription: (
      <>
        <span>
          Non-profit organizations include Corporations, Trusts, LLCs, and Unincorporated Associations that qualify for tax-exempt status under IRS Code IRC 501(a). 
          An example of Non-Profit Organizations includes: private foundations, educational organizations, public charities, veteran&apos;s organizations, business leagues, homeowners/condo associations, PTA/PTO or School Organizations and more.
        </span>
        <br />
        <br />
        <span>
          Sole Proprietors, Partnerships and for-profit organizations cannot be considered for tax-exempt status.
        </span>
        <br />
        <br />
        <Link href="/products/EINNonProfit" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_CCorp",
    path: "EINCCorp",
    iconSrc: 'images/V2/V2-reflection-2/c-corp-icon-blue.svg',
    category: "EIN",
    customerName: "Corporation",
    shortDescription: "You have already setup a legal C-Corp entity",
    detailedDescription: (
      <>
        <span>
          A Corporation is a legal entity established by filing Articles of Incorporation with the State, which grants it legal powers, rights, privileges and liabilities. 
          A Corporation can be established by a person or group of people with a charter from the Secretary of State. 
          After a Corporation is created, it becomes its own entity and generally has an indefinite lifespan.
        </span>
        <br />
        <br />
        <Link href="/products/EINCCorp" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_Partnership",
    path: "EINPartnership",
    iconSrc: 'images/V2/V2-reflection-2/partnership-icon-blue.svg',
    category: "EIN",
    customerName: "Partnership",
    shortDescription: "You are organized as a General partnership - Limited partnership (LP) - Limited liability partnership (LLP) - Limited liability limited partnership (LLLP)",
    detailedDescription: (
      <>
        <span>
          A Partnership is an unincorporated organization with two or more members. 
          The members of a Partnership carry on a trade or business venture and divide its profits. 
          Partners can be individuals, corporations, trusts, estates, and other partnerships. 
          Each partner contributes money, property, labor or skill, and expects to share in the profits and losses of the business. 
          The tax liability of the Partnership passes through to its partners.
        </span>
        <br />
        <br />
        <Link href="/products/EINPartnership" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_SCorp",
    path: "EINSCorp",
    iconSrc: 'images/V2/V2-reflection-2/s-corp-icon-blue.svg',
    category: "EIN",
    customerName: "S-Corporation",
    shortDescription: "You have already setup a legal S-Corp entity",
    detailedDescription: (
      <>
        <span>
          An S-Corporation is a type of corporation which has been established by filing Articles of Incorporation with the state’s Secretary of State. 
          It is an eligible domestic corporation that wants to avoid double taxation (once to the shareholders and again to the corporation). 
          The income of an S Corporation is taxed to the shareholders of the corporation rather than to the corporation itself. 
          S Corporations are generally favored by certain professions such as doctors, dentists and certain types of consultants.
        </span>
        <br />
        <br />
        <Link href="/products/EINSCorp" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_Church",
    path: "EINChurch",
    iconSrc: 'images/V2/V2-reflection-2/church-icon-blue.svg',
    category: "EIN",
    customerName: "Church Organization",
    shortDescription: "Your organization is part of an organized religion, and is formally organized as a distinct legal entity",
    detailedDescription: (
      <>
        <span>
          For tax purposes, a “church” refers to any organization claiming to be a church or any convention or association of churches. 
          The word “church” includes temples, mosques, and other houses of worship. 
          To be considered a church for tax purposes, a group must be part of an organized religion, must have a mission statement and be formally organized as a distinct legal entity.
        </span>
        <br />
        <br />
        <Link href="/products/EINChurch" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
  {
    code: "EIN_PSC",
    path: "EINPSC",
    iconSrc: 'images/V2/V2-reflection-2/personal-icon-blue.svg',
    category: "EIN",
    customerName: "Personal Service Corporation",
    shortDescription: "You have already setup a Personal Service Corporation (PSC) entity",
    detailedDescription: (
      <>
        <span>
          A Personal Service Corporation is a person, or group of people who establish a legal entity by filing Articles of Incorporation with the state’s Secretary of State. 
          Typically, a Personal Service Corporation is an organization in which the activity involves the performance of services in the field of health, law, engineering, architecture, accounting, actuarial science, performing arts, or consulting. 
          In a Personal Service Corporation, substantially all of the stock is owned by the employees who perform the services. 
          A Personal Service Corporation sells its ideas or expertise rather than tangible goods.
        </span>
        <br />
        <br />
        <Link href="/products/EINPSC" passHref>
          <LinkButton>
            Get started
          </LinkButton>
        </Link>
      </>
    ),
    forSale: true,
    popular: false,
  },
];
