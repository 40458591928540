import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Link from "next/link";
import axios from "axios";

import { FAQ_TERMS } from "../../../../constants/V2-reflection-2/faq";
import CollapsibleList from "../../CollapsibleList";
import { config } from "../../../../../../_config";

const { email } = config;
const StyledEntitiesFAQSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: #FFFFFF;

  padding-top: 4rem;
  padding-bottom: 4rem;

  .entities-faq-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    background-color: #001e5a;

    span {
      display: block;
      width: 980px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: 'Arial';
      font-weight: bold;
      font-size: 28px;
      line-height: calc(1.42857rem * (28 / 16));
      color: #FFFFFF;
    }
  }

  .entities-faq-container {
    max-width: 980px;
    padding-left: 15px;
    padding-right: 15px;

    .entities-faq-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-bottom: 0.5rem;

      .entities-faq-item-title {
        margin-bottom: 8px;
        font-family: 'Arial';
        font-weight: bold;
        font-size: 26px;
        line-height: calc(1.4rem * (26 / 16));
        color: #424242;
      }

      .entities-faq-item-text {
        margin-bottom: 1rem;
        color: #424242;

        a {
          text-decoration: underline;
          color: #001e5a;
        }

        #collapse-entities-faq-terms {
          margin-top: 16px;
          
          a {
            text-decoration: none;
            color: #001e5a;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .entities-faq-container {
      .entities-faq-item {
        .entities-faq-item-title {
          font-size: 1.75rem;
          line-height: 2.5rem;
        }
      }
    }
  }
`;

function EntitiesFAQSection() {
  const [price, setPrice] = useState("");

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_REACT_APP_API + "/category/EIN/products/prices?productCodes=EIN_SoleProp",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        const price = response.data.find(({ option }) => option === "STANDARD")?.price;
        setPrice(price);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <StyledEntitiesFAQSection>
      <div className="entities-faq-title">
        <span>
          Frequently Asked Questions
        </span>
      </div>
      <div className="entities-faq-container">
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              What is an EIN (Federal Tax ID Number)?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>
              An EIN (Employer Identification Number) is a nine-digit number issued by the IRS used to identify businesses and certain other entities. 
              An EIN number (also called a Federal Tax ID) is commonly used for taxing and banking purposes and to register your business with the federal government.
            </span>
            &nbsp;
            <a
              data-bs-toggle="collapse"
              role="button"
              href="#collapse-entities-faq-terms"
              aria-expanded="false"
              aria-controls="collapse-entities-faq-terms"
              className="collapsed"
            >
              Need some more explanation for commonly used terms? +More
            </a>
            <div id="collapse-entities-faq-terms" className="collapse">
              <CollapsibleList items={FAQ_TERMS} />
            </div>
          </div>
        </div>
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              Why Do I Need an EIN?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>1. To hire employees</span>
            <br />
            <span>2. Open a business bank account</span>
            <br />
            <span>3. File taxes</span>
          </div>
        </div>
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              How long does it take receive my EIN?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>
              Express delivery will be completed same day if placed during business hours. 
              Orders placed outside of business hours will be fulfilled next business day.
            </span>
            <br />
            <br />
            <span>
              Business Hours:
              <br />
              Monday through Friday, 9 AM - 5 PM PST
            </span>
          </div>
        </div>
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              What does the EIN filing service cost?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>
              The fee for our service is $<span>{price}</span> and includes a simplified EIN application and comprehensive application review by our team of experts before submitting to the IRS. 
              We work directly with the IRS on your behalf to address any follow-up questions to ensure your EIN order is completed. 
              We also provide support along the way and a customer portal to check your order status and retrieve your EIN and business information at any time.
            </span>
          </div>
        </div>
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              How do I check the status of my order?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>
              Go to your <Link href="/status"><a>order status</a></Link> page and input your order number at any time to check the status of your order. 
              You will also receive email and text message updates.
            </span>
          </div>
        </div>
        <div className="entities-faq-item">
          <div className="entities-faq-item-title">
            <span>
              How do I contact customer support about my order?
            </span>
          </div>
          <div className="entities-faq-item-text">
            <span>
              We have an EIN Customer Portal where you can always access your order status and business information 24/7. 
              In addition, you can always E-mail us at&nbsp;
              <a href={`mailto:${email}?subject=Mail from Contact Us USA Taxid Site`}>{email}</a>&nbsp;
              or click <Link href="/contactus"><a>here</a></Link> for additional customer support information
            </span>
          </div>
        </div>
      </div>
    </StyledEntitiesFAQSection>
  );
}

export default EntitiesFAQSection;
