import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

import { hexToRGBA } from "../../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledApplicationStepsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-top: 5rem;

  background: #FFFFFF;

  .application-title {
    display: flex;
    justify-content: center;

    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    background-color: #001e5a;

    span {
      display: block;
      width: 980px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: 'Arial';
      font-weight: bold;
      font-size: 28px;
      line-height: calc(1.42857rem * (28 / 16));
      color: #FFFFFF;
    }
  }

  .application-steps-container {
    display: flex;
    justify-content: center;

    max-width: 960px;

    .application-steps-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      width: calc(100% / 3);

      margin-bottom: 24px;
      padding-top: 24px;
      padding-left: 15px;
      padding-right: 15px;

      .application-steps-item-image {
        margin-bottom: 24px;

        img {
          width: 100px;
        }
      }

      .application-steps-item-title {
        margin-bottom: 16px;
        font-family: 'Arial';
        font-weight: bold;
        font-size: 20px;
        line-height: calc(1.42857rem * (20 / 16));
        text-align: center;
        color: #424242;
      }

      .application-steps-item-description {
        margin-bottom: 16px;
        font-family: 'Arial';
        font-size: 16px;
        line-height: calc(1.42857rem * (16 / 16));
        text-align: center;
        color: ${hexToRGBA('#424242', 0.8)};
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    .application-steps-container {
      flex-direction: column;

      .application-steps-item {
        width: 100%;
      }
    }
  }
`;

function ApplicationStepsSection() {
  return (
    <StyledApplicationStepsSection>
      <div className="application-title">
        <span>
          {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" ? "Online EIN/Tax ID Application Process" : "Online E-File Application Process"}
        </span>
      </div>
      <div className="application-steps-container">
        <div className="application-steps-item">
          <div className="application-steps-item-image">
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-4/steps-icon-1.svg`} alt="" />
          </div>
          <div className="application-steps-item-title">
            <span>
              1. Choose your entity type and complete our simplified application
            </span>
          </div>
          <div className="application-steps-item-description">
            <span>
              Avoid the headache and struggle of navigating federal filing systems and complicated application forms.
            </span>
          </div>
        </div>
        <div className="application-steps-item">
          <div className="application-steps-item-image">
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-4/steps-icon-2.svg`} alt="" />
          </div>
          <div className="application-steps-item-title">
            <span>
              2. Application review and error resolution before submitting to the IRS
            </span>
          </div>
          <div className="application-steps-item-description">
            <span>
              {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" ? "No more having to wait on hold with the IRS to resolve an issue with your Federal Tax ID / EIN application." : "No more having to wait on hold with the IRS to resolve an issue with your e-file submission."}
            </span>
          </div>
        </div>
        {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" ?
        <div className="application-steps-item">
          <div className="application-steps-item-image">
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-4/steps-icon-3.svg`} alt="" />
          </div>
          <div className="application-steps-item-title">
            <span>
              3. Receive Federal Tax ID / EIN and start running your business
            </span>
          </div>
          <div className="application-steps-item-description">
            <span>
              Email and text updates take the guesswork out the process. 
              Dedicated client portal gives you 24/7 access to your EIN and business information.
            </span>
          </div>
        </div> : <div className="application-steps-item">
            <div className="application-steps-item-image">
              <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-4/steps-icon-3.svg`} alt="" />
            </div>
            <div className="application-steps-item-title">
            <span>
              3. Filing Completed and start running your business
            </span>
            </div>
            <div className="application-steps-item-description">
            <span>
              Email and text updates take the guesswork out of the process. Dedicated client portal gives you 24/7 access to your application and business information.
            </span>
            </div>
          </div>
        }
      </div>
    </StyledApplicationStepsSection>
  );
 }
 
 export default ApplicationStepsSection;
 
