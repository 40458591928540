import React from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { parseDomain, ParseResultType } from "parse-domain";

const { publicRuntimeConfig } = getConfig();
const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  color: white;

  img {
    height: 40px;
    margin-top: ${skin === "irsein" ? "-14px": "-4px"};
    cursor: pointer;
  }

  .logo-title  {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: flex-start;

    .logo-text  {
      font-family: 'MerriweatherBold';
      font-size: 28px;
      line-height: 28px;
      font-weight: 600;
      white-space: nowrap;
    }

    .logo-subtext  {
      font-family: 'MerriweatherLight';
      font-size: 14px;
      line-height: 18px;
    }
  }
  .logo-title2  {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: left;

    .logo-text2  {
      font-family: 'MerriweatherBold';
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 4px;

    img {
      margin-top: 0;
      max-width: 95%;
    }

    .logo-title  {
      .logo-text  {
        font-size: 18px;
        line-height: 18px;
      }

      .logo-subtext  {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .logo-title2  {
      .logo-text2  {
        font-size: 11px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
`;

const Logo = () => {
  let subDomainName=[];
  let domainName = "USA-taxID";
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, subDomains } = parseResult;
    domainName = domain;
    subDomainName = subDomains;
  }
  return (
    <StyledLogo>
      <img
        src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-2/logo.svg`}
        alt="Logo"
      />
      {process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && process.env.NEXT_PUBLIC_BASE_PATH !== "/3" && process.env.NEXT_PUBLIC_BASE_PATH !== "/4" && process.env.NEXT_PUBLIC_BASE_PATH !== "/5" &&
        <span className="logo-title">
        {!subDomainName.includes("irs") && !subDomainName.includes("apply") && (!(subDomainName.includes("ein") && domainName==='tax-efile')) && domainName!=='us-taxfiling' && (
          <span className="logo-text">
            EIN APPLICATION (2025)
          </span>
        )}
          {(subDomainName.includes("irs") || subDomainName.includes("apply") || (subDomainName.includes("ein") && domainName==='tax-efile') || domainName==='us-taxfiling') && (
            <span className="logo-text">
            Tax ID/EIN APPLICATION (2025)
          </span>
          )}
      </span>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/2" && <span className="logo-title2">
        <span className="logo-text2">
          E-File Tax Application - 2025 IRS Online Application
        </span>
        <span className="logo-text2">
          IRS Authorized - Electronic Return Originator
        </span>
      </span>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/3" && <span className="logo-title2">
        <span className="logo-text2">
          Tax E-Filing Application - IRS E-File Application
        </span>
        <span className="logo-text2">
          IRS E-File Authorized - Electronic Return Originator
        </span>
      </span>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/4" && <span className="logo-title2">
        <span className="logo-text2">
          Tax E-Filing Application - IRS Authorized E-File Service
        </span>
        <span className="logo-text2">
          Certified Electronic Return Originator
        </span>
      </span>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/5" && <span className="logo-title">
          <span className="logo-text">
            (2025) - Online Federal EIN/Tax ID Filing
          </span>
      </span>
      }
    </StyledLogo>
  );
};

export default Logo;
