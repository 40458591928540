const initialState = {
  order: null,
  loading: false,
  error: null,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case "PAYMENT_STARTED": {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case "PAYMENT_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "PAYMENT_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case "ORDER_STATUS_STARTED": {
      return {
        ...state,
        loading: true,
      };
    }
    case "ORDER_STATUS_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "CLEAR_ERROR": {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case "CLEAR_ORDER": {
      return {
        ...state,
        order: null,
        loading: false,
        error: null,
      };
    }
    case "ORDER_STATUS_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case "LLC_STARTED": {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case "LLC_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "LLC_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case "TRADEMARK_STARTED": {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case "TRADEMARK_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "TRADEMARK_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case "BOI_STARTED": {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case "BOI_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "BOI_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case "MONEXA_STARTED": {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case "MONEXA_SUCCESS": {
      return {
        ...state,
        order: action.order,
        loading: false,
        error: null,
      };
    }
    case "MONEXA_FAILURE": {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
