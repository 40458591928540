import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { Seo } from "../../../../component/SEO";
import Header from "../Header";
import Footer from "../Footer";
import { Favicons } from "../../../../component/Favicons";

const StyledLayout = styled.div``;
const StyledDivider = styled.div`
  min-height: 8px;
  background: #002346;
`;
function Layout({ children }) {
	const router = useRouter();

	return (
		<StyledLayout>
			<Favicons />
      <Seo title="File For EIN Online | Home" description="" />
      <Header
				showIncorporateLLCButton={!router.asPath?.includes("/products")}
			/>
			<StyledDivider />
			{children}
      <Footer />
		</StyledLayout>
		
	);
}

export default Layout;
