import React from "react";
import styled from "styled-components";

import { hexToRGBA } from "../../../../utils/theme";

const StyledCollapsibleItem = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;

  box-shadow: 1px 1px 5px #cccccc;
  background: ${hexToRGBA('#000000', 0.03)};

  .item-title  {
    display: flex;
    justify-content: space-between;

    font-family: 'Arial';
    font-weight: bold;
    font-size: 18px;
    line-height: calc(1.42857rem * (18 / 16));
    text-decoration: none;
    color: #001e5a;

    &:hover {
      text-decoration: underline;
    }
  }

  .item-collapsible {
    margin-top: 18px;

    span {
      font-family: 'Arial';
      font-size: 12px;
      line-height: calc(1.42857rem * (13 / 16));
      color: #424242;
    }

    ul {
      list-style: disc;
      padding-left: 32px;

      li {
        font-family: 'Arial';
        font-size: 12px;
        line-height: calc(1.42857rem * (13 / 16));
        color: #424242;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {

  }
`;

function CollapsibleItem({ id, title, collapsibleContent }) {
  return (
    <StyledCollapsibleItem>
      <a
        data-bs-toggle="collapse"
        role="button"
        href={`#collapse-${id}`}
        aria-expanded="false"
        aria-controls={`collapse-${id}`}
        className="item-title collapsed"
      >
        {title}
      </a>
      <div id={`collapse-${id}`} className="item-collapsible collapse">
        <span>{collapsibleContent}</span>
      </div>
    </StyledCollapsibleItem>
  );
}

export default CollapsibleItem;
