import React from "react";
import { getDaysInMonth } from "date-fns";

import {
  FORM_FIELD_KEYS,
  SUFFIX_OPTIONS,
  TITLE_OPTIONS,
  STATE_OPTIONS,
  MONTH_OPTIONS,
  DAY_OPTIONS,
  ACTIVITY_REASON_OPTIONS,
  TRUST_TYPE_OPTIONS,
  ORG_TYPE_OPTIONS,
  OTHER_ORG_TYPE_OPTIONS,
} from "../../constants/forms";

import {
  validateFirstName,
  validateMiddleName,
  validateLastName,
  validateDBAName,
  validateLegalName,
  validateAddress1,
  validateAddress2,
  validateCity,
  validateCounty,
  validateYear,
  validateTradeName,
  validateAltCompanyName,
  validateDriverLicenceOrID,
} from "../validators";

const fieldProps = {
  [FORM_FIELD_KEYS.firstName]: {
    id: FORM_FIELD_KEYS.firstName,
    label: "First Name",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.lastName]: {
    id: FORM_FIELD_KEYS.lastName,
    label: "Last Name",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.customerFirstName]: {
    id: FORM_FIELD_KEYS.customerFirstName,
    label: "First Name",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.customerMiddleName]: {
    id: FORM_FIELD_KEYS.customerMiddleName,
    label: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.customerLastName]: {
    id: FORM_FIELD_KEYS.customerLastName,
    label: "Last Name",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.customerPhone]: {
    id: FORM_FIELD_KEYS.customerPhone,
    label: "Phone Number",
    required: true,
    type: "phone",
    mask: "(999) 999-9999",
    placeholder: "(123) 456-7890",
    pattern: ".{14,}",
    maxLength: "14",
    defaultInvalidMessage: "You must provide a valid phone number",
  },
  [FORM_FIELD_KEYS.customerEmail]: {
    id: FORM_FIELD_KEYS.customerEmail,
    label: "Email address",
    required: true,
    type: "email",
    pattern:
      "^([a-zA-Z0-9_\\-\\+\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$",
    maxLength: "30",
    defaultInvalidMessage: "You must provide a valid email address",
  },
  [FORM_FIELD_KEYS.questionsOrder]: {
    id: FORM_FIELD_KEYS.questionsOrder,
    label: "Have you placed and order with us?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.orderId]: {
    id: FORM_FIELD_KEYS.orderId,
    label: "Order Number",
    required: true,
    defaultInvalidMessage: "Please enter order number",
  },
  [FORM_FIELD_KEYS.message]: {
    id: FORM_FIELD_KEYS.message,
    label: "Message",
    required: true,
    minLength: "5",
    maxLength: "2147483647",
    defaultInvalidMessage: "Message length must be longer than 5 characters",
  },
  [FORM_FIELD_KEYS.legalName]: {
    id: FORM_FIELD_KEYS.legalName,
    hint: (
      <>
        The only punctuation and special characters allowed are hyphen (-) and ampersand (&). The legal name may not
        contain any of the following endings: Corp, LLC, PLLC, LC, Inc.
      </>
    ),
    required: true,
    pattern:
      "(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][iI][nN][cC]$))^[ a-zA-Z0-9&\\-]+$",
    maxLength: "70",
    validate: validateLegalName,
  },
  [FORM_FIELD_KEYS.dbaname]: {
    id: FORM_FIELD_KEYS.dbaname,
    placeholder: "Trade Name / DBA (optional)",
    hint: (
      <>
        A trade name or doing business as name is another name under which the business or individual operates. These
        names are identified by the terms &apos;trading as&apos;(TA), &apos;doing business as&apos;(DBA), or &apos;also
        known as&apos; (AKA). To file for a DBA, a separate filing with your state and county must be completed. We will
        send you instructions on how to complete this process.
        <br />
        <br />
        The trade name may not contain any of the following endings: LLC, PLLC, LC, PA, Corp, or Inc.
      </>
    ),
    required: false,
    pattern:
      "(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][pP][aA]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))^[ a-zA-Z0-9&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Name cannot contain LLC",
    validate: validateDBAName,
  },
  [FORM_FIELD_KEYS.ownerTitle]: {
    id: FORM_FIELD_KEYS.ownerTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.ownerSuffixName]: {
    id: FORM_FIELD_KEYS.ownerSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.ownerFirstName]: {
    id: FORM_FIELD_KEYS.ownerFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.ownerMiddleName]: {
    id: FORM_FIELD_KEYS.ownerMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.ownerLastName]: {
    id: FORM_FIELD_KEYS.ownerLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.ownerSSN]: {
    id: FORM_FIELD_KEYS.ownerSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.corpAddressAddress1]: {
    id: FORM_FIELD_KEYS.corpAddressAddress1,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.corpAddressAddress2]: {
    id: FORM_FIELD_KEYS.corpAddressAddress2,
    required: false,
    placeholder: "Address 2",
    maxLength: "30",
    validate: validateAddress2,
  },
  [FORM_FIELD_KEYS.corpAddressCity]: {
    id: FORM_FIELD_KEYS.corpAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.corpAddressZip]: {
    id: FORM_FIELD_KEYS.corpAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.corpAddressState]: {
    id: FORM_FIELD_KEYS.corpAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.corpAddressCounty]: {
    id: FORM_FIELD_KEYS.corpAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeAddressAddress1]: {
    id: FORM_FIELD_KEYS.representativeAddressAddress1,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.representativeAddressAddress2]: {
    id: FORM_FIELD_KEYS.representativeAddressAddress2,
    required: false,
    placeholder: "Address 2",
    maxLength: "30",
    validate: validateAddress2,
  },
  [FORM_FIELD_KEYS.representativeAddressCity]: {
    id: FORM_FIELD_KEYS.representativeAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeAddressZip]: {
    id: FORM_FIELD_KEYS.representativeAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeAddressState]: {
    id: FORM_FIELD_KEYS.representativeAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.representativeAddressCounty]: {
    id: FORM_FIELD_KEYS.representativeAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeAddressAddress1]: {
    id: FORM_FIELD_KEYS.trusteeAddressAddress1,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.trusteeAddressAddress2]: {
    id: FORM_FIELD_KEYS.trusteeAddressAddress2,
    required: false,
    placeholder: "Address 2",
    maxLength: "30",
    validate: validateAddress2,
  },
  [FORM_FIELD_KEYS.trusteeAddressCity]: {
    id: FORM_FIELD_KEYS.trusteeAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeAddressZip]: {
    id: FORM_FIELD_KEYS.trusteeAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeAddressState]: {
    id: FORM_FIELD_KEYS.trusteeAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.trusteeAddressCounty]: {
    id: FORM_FIELD_KEYS.trusteeAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.altAddress]: {
    id: FORM_FIELD_KEYS.altAddress,
    label: "Check this box if your mailing address is different than your physical address",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.altAddressAddress1]: {
    id: FORM_FIELD_KEYS.altAddressAddress1,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.altAddressAddress2]: {
    id: FORM_FIELD_KEYS.altAddressAddress2,
    required: false,
    placeholder: "Address 2",
    maxLength: "30",
    validate: validateAddress2,
  },
  [FORM_FIELD_KEYS.altAddressCity]: {
    id: FORM_FIELD_KEYS.altAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.altAddressZip]: {
    id: FORM_FIELD_KEYS.altAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.altAddressState]: {
    id: FORM_FIELD_KEYS.altAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.altAddressCounty]: {
    id: FORM_FIELD_KEYS.altAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.startDateMonth]: {
    id: FORM_FIELD_KEYS.startDateMonth,
    required: true,
    label: "Date Business Started",
    placeholder: "Month",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.startDateDay]: {
    id: FORM_FIELD_KEYS.startDateDay,
    required: true,
    emptyLabel: true,
    placeholder: "Day",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.startDateYear]: {
    id: FORM_FIELD_KEYS.startDateYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Year",
    validate: validateYear,
  },
  [FORM_FIELD_KEYS.dateOfDeathMonth]: {
    id: FORM_FIELD_KEYS.dateOfDeathMonth,
    required: true,
    label: "Date Business Started",
    placeholder: "Month",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.dateOfDeathDay]: {
    id: FORM_FIELD_KEYS.dateOfDeathDay,
    required: true,
    emptyLabel: true,
    placeholder: "Day",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.dateOfDeathYear]: {
    id: FORM_FIELD_KEYS.dateOfDeathYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
    hint: (
      <>
        This is the date on which the entity began doing business. If you are starting a new business, enter the
        starting date of the business. If the business you acquired is already operating, enter the date you acquired
        the business. If you are changing the form of ownership of your business, enter the date the new ownership
        entity began.
      </>
    ),
    defaultInvalidMessage: "Please provide Year",
    validate: validateYear,
  },
  [FORM_FIELD_KEYS.closingMonth]: {
    id: FORM_FIELD_KEYS.closingMonth,
    required: true,
    label: "Accounting Year End",
    placeholder: "Month",
    hint: (
      <>
        The closing month of the accounting year is the last month of your accounting year or tax year. An accounting or
        tax year is usually 12 consecutive months, based on either a calendar year or a fiscal year (including a period
        of 52 or 53 weeks).
        <br />A calendar year is 12 consecutive months ending on December 31. A fiscal year is either 12 consecutive
        months ending on the last day of any month (other than December) or a special 52-53 week year ending on a fixed
        day of the week (for example, the last Friday in March rather than March 31). For more information on accounting
        periods, see Pub. 538.
      </>
    ),
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.activityReasonForApplying]: {
    id: FORM_FIELD_KEYS.activityReasonForApplying,
    required: true,
    placeholder: "Reason for applying",
    hint: "Choose one reason that best describes why you are applying for an EIN",
    defaultInvalidMessage: "Please provide a reason for applying",
    options: ACTIVITY_REASON_OPTIONS,
  },
  [FORM_FIELD_KEYS.activityPrimaryActivity]: {
    id: FORM_FIELD_KEYS.activityPrimaryActivity,
    required: true,
    placeholder: "Primary Activity",
    hint: "Describe your type of business and primary activity and services you do or will provide",
    defaultInvalidMessage: "Please select the business activity",
  },
  [FORM_FIELD_KEYS.productDescription]: {
    id: FORM_FIELD_KEYS.productDescription,
    required: true,
    placeholder: "Specific Products or Services*",
    pattern: "^[a-zA-Z][ a-zA-Z]{1,50}$",
    maxLength: "50",
    onKeyPress: (event) => {
      if (!/[ a-zA-Z]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.activitySpecificProducts]: {
    id: FORM_FIELD_KEYS.activitySpecificProducts,
    required: true,
    placeholder: "Please select",
    defaultInvalidMessage: "Please describe the products sold or services provided",
  },
  [FORM_FIELD_KEYS.productExtraQuestion]: {
    required: true,
    placeholder: "Please select",
    defaultInvalidMessage: "Please select",
  },
  [FORM_FIELD_KEYS.questionsHighway]: {
    id: FORM_FIELD_KEYS.questionsHighway,
    required: false,
    label: "Does your business own a highway motor vehicle weighing 55,000 pounds or more?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No.
      </>
    ),
  },
  [FORM_FIELD_KEYS.questionsGambling]: {
    id: FORM_FIELD_KEYS.questionsGambling,
    required: false,
    label: "Does your business involve gambling?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No.
      </>
    ),
  },
  [FORM_FIELD_KEYS.questionsATF]: {
    id: FORM_FIELD_KEYS.questionsATF,
    required: false,
    label: "Does your business sell or manufacture alcohol, tobacco, or firearms?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No.
      </>
    ),
  },
  [FORM_FIELD_KEYS.questionsExcise]: {
    id: FORM_FIELD_KEYS.questionsExcise,
    required: false,
    label: "Does your business pay federal excise taxes?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No.
      </>
    ),
  },
  [FORM_FIELD_KEYS.questionsAppliedBefore]: {
    id: FORM_FIELD_KEYS.questionsAppliedBefore,
    required: false,
    label: "Has this proprietorship ever received or applied for an EIN before?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No. If you select yes, provide details in the fields that will appear
        below.
      </>
    ),
  },
  [FORM_FIELD_KEYS.acceptCreditCardPayments]: {
    id: FORM_FIELD_KEYS.acceptCreditCardPayments,
    required: false,
    label: "Does your business plan to or currently accept Credit Card payments?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.payEmployeesViaPayroll]: {
    id: FORM_FIELD_KEYS.payEmployeesViaPayroll,
    required: false,
    label: "Does your business plan to pay employees via payroll?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.previousEINNumberFirst2]: {
    id: FORM_FIELD_KEYS.previousEINNumberFirst2,
    required: true,
    label: "Previous EIN number, first 2 digits",
    placeholder: "XX",
    mask: "99",
    pattern: ".{2,}",
    maxLength: "2",
    defaultInvalidMessage: "Please specify the previous EIN",
  },
  [FORM_FIELD_KEYS.previousEINNumberLast7]: {
    id: FORM_FIELD_KEYS.previousEINNumberLast7,
    required: true,
    label: "Previous EIN number, last 7 digits",
    placeholder: "XXXXXXX",
    mask: "9999999",
    pattern: ".{7,}",
    maxLength: "7",
    defaultInvalidMessage: "Please specify the previous EIN",
  },
  [FORM_FIELD_KEYS.questionsHire]: {
    id: FORM_FIELD_KEYS.questionsHire,
    required: false,
    label: "Do you currently have, or plan to hire employees within the next year (not including owners)?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Please select yes if this applies to your business.
        <br />
        The most common answer to this question is No. If you select yes, provide details in the fields that will appear
        below.
      </>
    ),
  },
  [FORM_FIELD_KEYS.payLessThan4k]: {
    id: FORM_FIELD_KEYS.payLessThan4k,
    required: false,
    label:
      "Do you expect your employment tax liability to be $1,000 or less in a full calendar year? (January-December)?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
    hint: (
      <>
        Do you have, or do you expect to have, any employees who will receive Forms W-2 in the next 12 months?
        <br />A Form W-2 (Wage and Tax Statement) is issued by an employer to his or her employees showing taxes
        deducted from their yearly pay. Taxes covered on the W-2 are: federal income tax, Social Security tax and
        Medicare tax
      </>
    ),
  },
  [FORM_FIELD_KEYS.annualTaxes]: {
    id: FORM_FIELD_KEYS.annualTaxes,
    required: false,
    label: "Do you want to file employment tax annually instead of quarterly?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.numberOfAgricultureEmployees]: {
    id: FORM_FIELD_KEYS.numberOfAgricultureEmployees,
    required: true,
    label: "Number of Agricultural Employees",
    hint: (
      <>
        <strong>Number of OR expected number of Agricultural Employees</strong>
        <br />
        Agricultural employees include any person who works on a farm producing crops and/or raising livestock. Farms
        include stock, dairy, poultry, fruit, fur-bearing animal, and truck farms, as well as orchards, plantations,
        ranches, nurseries, ranges, green houses, and other similar structures used mainly for raising agricultural or
        horticultural commodities.
        <br />
        Forestry and lumbering are not &quot;farm work&quot; unless the products are for use in the employer&apos;s
        farming operations.
        <br />
        Farm workers or agricultural employees include those who:
        <br />
        <ul style={{ fontSize: "16px" }}>
          <li style={{ fontSize: "16px", fontFamily: "Source Sans Pro" }}>
            Raise or harvest agricultural or horticultural products, or care for the farm and equipment
          </li>
          <li style={{ fontSize: "16px", fontFamily: "Source Sans Pro" }}>
            Handle, process, or package any agricultural or horticultural commodity, if over half is produced on the
            farm
          </li>
          <li style={{ fontSize: "16px", fontFamily: "Source Sans Pro" }}>
            {" "}
            Do work related to cotton ginning, turpentine, or gum resin products
          </li>
          <li style={{ fontSize: "16px", fontFamily: "Source Sans Pro" }}>
            Do housework in the private home of a farm operated for profit.
          </li>
        </ul>
      </>
    ),
    pattern: "[0-9]*",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  [FORM_FIELD_KEYS.numberOfHouseholdeEmployees]: {
    id: FORM_FIELD_KEYS.numberOfHouseholdeEmployees,
    required: true,
    label: "Number of Household Employees",
    pattern: "[0-9]*",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  [FORM_FIELD_KEYS.numberOfOtherEmployees]: {
    id: FORM_FIELD_KEYS.numberOfOtherEmployees,
    required: true,
    label: "Number of Other Employees",
    hint: (
      <>
        <strong>Number of OR expected number of Other Employees</strong>
        <br />
        A general rule is that anyone who performs services for you is your employee if you can control what work will
        be done and how it will be done. These employees will be issued a W-2 form at the end of the year.
        <br />
        Employers must file Form W-2 for wages paid to each employee from whom:
        <br />
        Income, Social Security, or Medicare tax was withheld or Income tax would have been withheld if the employee had
        claimed no more than one withholding allowance or had not claimed exemption from withholding on Form W-4
        (Employee&apos;s Withholding Allowance Certificate).
        <br />
        Note: Total number of employees must be at least 1.
      </>
    ),
    defaultInvalidMessage: "Please specify a number. Total number of employees must be at least 1",
    pattern: "[0-9]*",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  [FORM_FIELD_KEYS.firstDateWagesMonth]: {
    id: FORM_FIELD_KEYS.firstDateWagesMonth,
    required: true,
    label: "First date wages or annuities were or will be paid",
    placeholder: "Month",
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstDateWagesDay]: {
    id: FORM_FIELD_KEYS.firstDateWagesDay,
    required: true,
    emptyLabel: true,
    placeholder: "Day",
    defaultInvalidMessage: "Please provide Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstDateWagesYear]: {
    id: FORM_FIELD_KEYS.firstDateWagesYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
    defaultInvalidMessage: "Please provide Year",
    validate: validateYear,
  },
  [FORM_FIELD_KEYS.primaryPartnerTitle]: {
    id: FORM_FIELD_KEYS.primaryPartnerTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.primaryPartnerSuffixName]: {
    id: FORM_FIELD_KEYS.primaryPartnerSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.primaryPartnerFirstName]: {
    id: FORM_FIELD_KEYS.primaryPartnerFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.primaryPartnerMiddleName]: {
    id: FORM_FIELD_KEYS.primaryPartnerMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.primaryPartnerLastName]: {
    id: FORM_FIELD_KEYS.primaryPartnerLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.primaryPartnerSSN]: {
    id: FORM_FIELD_KEYS.primaryPartnerSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.principalOfficerTitle]: {
    id: FORM_FIELD_KEYS.principalOfficerTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.principalOfficerSuffixName]: {
    id: FORM_FIELD_KEYS.principalOfficerSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.principalOfficerFirstName]: {
    id: FORM_FIELD_KEYS.principalOfficerFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.principalOfficerMiddleName]: {
    id: FORM_FIELD_KEYS.principalOfficerMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.principalOfficerLastName]: {
    id: FORM_FIELD_KEYS.principalOfficerLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.principalOfficerSSN]: {
    id: FORM_FIELD_KEYS.principalOfficerSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.organizedState]: {
    id: FORM_FIELD_KEYS.organizedState,
    required: true,
    placeholder: "State Organized",
    hint: (
      <>
        State/Territory where Articles of Incorporation are (or will be) filed
        <br />
        Articles of incorporation must be filed with a state for a business to incorporate. To file, an applicant
        generally must include the name and address of the corporation, its general purpose, and the number and type of
        shares of stock to be issued. Each state has its own system of approval of articles, prohibits names which are
        confusingly similar to those of existing corporations, sets specific requirements for non-profits (charitable,
        religious, educational, public benefit, and so forth), and regulates the issuance of shares of stock.
      </>
    ),
    defaultInvalidMessage: "Please provide the state where the company was organized",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.deceasedSuffixName]: {
    id: FORM_FIELD_KEYS.deceasedSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.deceasedFirstName]: {
    id: FORM_FIELD_KEYS.deceasedFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.deceasedMiddleName]: {
    id: FORM_FIELD_KEYS.deceasedMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.deceasedLastName]: {
    id: FORM_FIELD_KEYS.deceasedLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.deceasedSSN]: {
    id: FORM_FIELD_KEYS.deceasedSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.representativeTitle]: {
    id: FORM_FIELD_KEYS.representativeTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.representativeSuffixName]: {
    id: FORM_FIELD_KEYS.representativeSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.representativeFirstName]: {
    id: FORM_FIELD_KEYS.representativeFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeMiddleName]: {
    id: FORM_FIELD_KEYS.representativeMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeLastName]: {
    id: FORM_FIELD_KEYS.representativeLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.representativeSSN]: {
    id: FORM_FIELD_KEYS.representativeSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.responsiblePartyTitle]: {
    id: FORM_FIELD_KEYS.responsiblePartyTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.responsiblePartySuffixName]: {
    id: FORM_FIELD_KEYS.responsiblePartySuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.responsiblePartyFirstName]: {
    id: FORM_FIELD_KEYS.responsiblePartyFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.responsiblePartyMiddleName]: {
    id: FORM_FIELD_KEYS.responsiblePartyMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.responsiblePartyLastName]: {
    id: FORM_FIELD_KEYS.responsiblePartyLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.responsiblePartySSN]: {
    id: FORM_FIELD_KEYS.responsiblePartySSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.nonProfitType]: {
    id: FORM_FIELD_KEYS.nonProfitType,
    placeholder: "Type of Non-Profit",
    hint: "Example: Educational",
    required: false,
    defaultInvalidMessage: "Please specify the type of organization",
  },
  [FORM_FIELD_KEYS.LLCMembers]: {
    id: FORM_FIELD_KEYS.LLCMembers,
    required: true,
    label: "Number of members in the LLC",
    pattern: "^(?:[1-9]\\d{0,4}|100000)",
    defaultInvalidMessage: "Please provide the number of LLC members",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  [FORM_FIELD_KEYS.husbandAndWife]: {
    id: FORM_FIELD_KEYS.husbandAndWife,
    required: false,
    label: "Are the members husband and wife?",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.multiMember]: {
    id: FORM_FIELD_KEYS.multiMember,
    required: false,
    options: [
      {
        label: "We elect to be classified as a multi-member LLC",
        value: "false",
      },
      {
        label: "We elect to be classified as a single-member LLC",
        value: "true",
      },
    ],
  },
  [FORM_FIELD_KEYS.taxClassification]: {
    id: FORM_FIELD_KEYS.taxClassification,
    required: false,
    options: [
      {
        label: "Individual/Partnership: Not taxed as a separate entity from owner(s)",
        value: "Individual",
      },
      {
        label: "S-Corporation: Planning to elect an S-Corporation tax structure",
        value: "SCorp",
      },
      {
        label: "Corporation: LLC is planning to elect a Corporation tax structure",
        value: "Corporation",
      },
    ],
  },
  [FORM_FIELD_KEYS.createrSuffixName]: {
    id: FORM_FIELD_KEYS.createrSuffixName,
    placeholder: "Suffix (optional)",
    required: false,
    options: SUFFIX_OPTIONS,
  },
  [FORM_FIELD_KEYS.createrFirstName]: {
    id: FORM_FIELD_KEYS.createrFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.createrMiddleName]: {
    id: FORM_FIELD_KEYS.createrMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.createrLastName]: {
    id: FORM_FIELD_KEYS.createrLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.createrSSN]: {
    id: FORM_FIELD_KEYS.createrSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.trusteeTitle]: {
    id: FORM_FIELD_KEYS.trusteeTitle,
    placeholder: "Title*",
    required: true,
    defaultInvalidMessage: "Please select a valid title",
    options: TITLE_OPTIONS,
  },
  [FORM_FIELD_KEYS.trusteeFirstName]: {
    id: FORM_FIELD_KEYS.trusteeFirstName,
    placeholder: "First Name*",
    hint: "First name of the responsible party. The first, middle, and last name must match exactly as on file with the IRS and SSA.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateFirstName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeMiddleName]: {
    id: FORM_FIELD_KEYS.trusteeMiddleName,
    placeholder: "Middle Name (optional)",
    hint: "OPTIONAL: Middle name of responsible party. The name must match exactly as on file with the IRS and SSA.",
    required: false,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateMiddleName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeLastName]: {
    id: FORM_FIELD_KEYS.trusteeLastName,
    placeholder: "Last Name*",
    hint: "The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match the IRS records, this will cause delays in the processing of your EIN Application.",
    required: true,
    pattern: "^[ a-zA-Z&\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a valid name",
    validate: validateLastName,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trusteeSSN]: {
    id: FORM_FIELD_KEYS.trusteeSSN,
    placeholder: "Social Security Number*",
    hint: (
      <>
        Your valid Social Security Number is needed by the IRS to obtain an EIN. Rest assured, all Social Security
        Numbers are stored in a fully protected fashion.
        <br />
        <br />
        The name must match exactly as on file with the IRS and SSA. If this information submitted here does not match
        the IRS records, this will cause delays in the processing of your EIN Application.
        <br />
        <br />
        Social Security Number (SSN) is a permanently assigned nine-digit number issued by the Social Security
        Administration to identify an individual income tax account.
        <br />
        <br />
        An Individual Taxpayer Identification Number (ITIN) is a tax processing number. The IRS issues ITINs to
        individuals who are required to have a U.S. Taxpayer Identification Number but who do not have, and are not
        eligible to obtain, an SSN.
        <br />
        <br />
        If you have an ITIN, unfortunately you cannot obtain an EIN ONLINE.
      </>
    ),
    required: true,
    mask: "999-99-9999",
    pattern: ".{11,}",
    maxLength: "11",
    defaultInvalidMessage: "Please provide a valid Social Security Number Ex: 123-45-6789",
  },
  [FORM_FIELD_KEYS.trustType]: {
    id: FORM_FIELD_KEYS.trustType,
    required: true,
    placeholder: "Type of Trust",
    defaultInvalidMessage: "Please provide a valid trust type",
    options: TRUST_TYPE_OPTIONS,
  },
  [FORM_FIELD_KEYS.processingOption]: {
    id: FORM_FIELD_KEYS.processingOption,
    required: false,
  },
  [FORM_FIELD_KEYS.cardNumber]: {
    required: true,
    label: "Credit Card",
    placeholder: "0000 0000 0000 0000",
  },
  [FORM_FIELD_KEYS.cvc]: {
    required: true,
    label: "CVC/CVV",
    placeholder: "CVC",
    pattern: "\\d{3,4}",
    maxLength: "4",
    onKeyPress: (event) => {
      if (!/[\d{3,4}]/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  [FORM_FIELD_KEYS.cardMonth]: {
    id: FORM_FIELD_KEYS.cardMonth,
    required: true,
    label: "Exp. Date",
    placeholder: "Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.cardYear]: {
    id: FORM_FIELD_KEYS.cardYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
  },
  [FORM_FIELD_KEYS.billingAddress]: {
    id: FORM_FIELD_KEYS.billingAddress,
    label: "My billing address is different",
    labelOn: "Yes",
    labelOff: "No",
    valueOn: "true",
    valueOff: "false",
  },
  [FORM_FIELD_KEYS.billingAddressAddress1]: {
    id: FORM_FIELD_KEYS.billingAddressAddress1,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.billingAddressAddress2]: {
    id: FORM_FIELD_KEYS.billingAddressAddress2,
    required: false,
    placeholder: "Address 2",
    maxLength: "30",
    validate: validateAddress2,
  },
  [FORM_FIELD_KEYS.billingAddressCity]: {
    id: FORM_FIELD_KEYS.billingAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.billingAddressZip]: {
    id: FORM_FIELD_KEYS.billingAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.billingAddressState]: {
    id: FORM_FIELD_KEYS.billingAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.tradeName]: {
    id: FORM_FIELD_KEYS.tradeName,
    required: true,
    label: "Limited Liability Company Name",
    pattern: "^[ a-zA-Z0-9\\&-]+$",
    maxLength: "35",
    defaultInvalidMessage: "Please provide Limited Liability Company Name",
    validate: validateTradeName,
  },
  [FORM_FIELD_KEYS.altCompanyName]: {
    id: FORM_FIELD_KEYS.altCompanyName,
    hint: (
      <>Please provide an alternate name for your LLC. This will only be used if your first selection is unavailable.</>
    ),
    required: true,
    label: "Alternate Company Name",
    pattern: "^[ a-zA-Z0-9\\&-]+$",
    maxLength: "35",
    defaultInvalidMessage: "Please provide Limited Liability Company Name",
    validate: validateAltCompanyName,
  },
  [FORM_FIELD_KEYS.residentAgent]: {
    id: FORM_FIELD_KEYS.residentAgent,
    labelOff: "Yes, Use Resident Agent",
    labelOn: "No, I Will Use My Home Address",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.designator]: {
    id: FORM_FIELD_KEYS.designator,
    required: true,
    defaultInvalidMessage: "You must provide Designator?",
  },
  [FORM_FIELD_KEYS.physicalAddressAddress]: {
    id: FORM_FIELD_KEYS.physicalAddressAddress,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.physicalAddressCity]: {
    id: FORM_FIELD_KEYS.physicalAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.physicalAddressZip]: {
    id: FORM_FIELD_KEYS.physicalAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.physicalAddressState]: {
    id: FORM_FIELD_KEYS.physicalAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.physicalAddressCounty]: {
    id: FORM_FIELD_KEYS.physicalAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.mailingAddressAddress]: {
    id: FORM_FIELD_KEYS.mailingAddressAddress,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.mailingAddressCity]: {
    id: FORM_FIELD_KEYS.mailingAddressCity,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.mailingAddressZip]: {
    id: FORM_FIELD_KEYS.mailingAddressZip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.mailingAddressState]: {
    id: FORM_FIELD_KEYS.mailingAddressState,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.mailingAddressCounty]: {
    id: FORM_FIELD_KEYS.mailingAddressCounty,
    required: true,
    placeholder: "County",
    autoComplete: "none",
    pattern: "^[ a-zA-Z&\\-]+$",
    defaultInvalidMessage: "Please provide a County",
    validate: validateCounty,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.trademarkType]: {
    id: FORM_FIELD_KEYS.trademarkType,
    required: false,
    options: [
      {
        label: "Name",
        value: "NAME",
      },
      {
        label: "Slogan",
        value: "SLOGAN",
      },
      {
        label: "Logo",
        value: "LOGO",
      },
    ],
  },
  [FORM_FIELD_KEYS.markDesc]: {
    id: FORM_FIELD_KEYS.markDesc,
    required: true,
  },
  [FORM_FIELD_KEYS.literalElements]: {
    id: FORM_FIELD_KEYS.literalElements,
    required: true,
    label: "Description of your logo",
    placeholder: "Enter the description of your logo",
    defaultInvalidMessage: "You must provide the description",
  },
  [FORM_FIELD_KEYS.logoColoredVariety]: {
    id: FORM_FIELD_KEYS.logoColoredVariety,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.colorsInLogo]: {
    id: FORM_FIELD_KEYS.colorsInLogo,
    required: true,
    label: "A list of the colors that appear in your logo",
    placeholder: "Enter the list of the colors",
    defaultInvalidMessage: "You must provide the list of colors",
  },
  [FORM_FIELD_KEYS.logoFile]: {
    id: FORM_FIELD_KEYS.logoFile,
    required: true,
    label: "Upload logo file",
  },
  [FORM_FIELD_KEYS.isIncludeLivingPersonName]: {
    id: FORM_FIELD_KEYS.isIncludeLivingPersonName,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.isIncludeNicknameName]: {
    id: FORM_FIELD_KEYS.isIncludeNicknameName,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.ownName]: {
    id: FORM_FIELD_KEYS.ownName,
    required: true,
    label: "Legal Name",
    placeholder: "Enter the legal name",
    defaultInvalidMessage: "You must provide the legal name",
  },
  [FORM_FIELD_KEYS.isOtherLivingName]: {
    id: FORM_FIELD_KEYS.isOtherLivingName,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.includeTranslationWords]: {
    id: FORM_FIELD_KEYS.includeTranslationWords,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.translation]: {
    id: FORM_FIELD_KEYS.translation,
    required: true,
    label: "English translation",
    placeholder: "Enter the English translation or transliteration",
    defaultInvalidMessage: "You must provide the English translation",
  },
  [FORM_FIELD_KEYS.currentTrademark]: {
    id: FORM_FIELD_KEYS.currentTrademark,
    labelOff: "Yes",
    labelOn: "No",
    valueOff: "true",
    valueOn: "false",
  },
  [FORM_FIELD_KEYS.firstUseAnywhereMonth]: {
    id: FORM_FIELD_KEYS.firstUseAnywhereMonth,
    required: true,
    label: "First Use Anywhere",
    placeholder: "Month",
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstUseAnywhereDay]: {
    id: FORM_FIELD_KEYS.firstUseAnywhereDay,
    required: true,
    emptyLabel: true,
    placeholder: "Day",
    defaultInvalidMessage: "Please provide Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstUseAnywhereYear]: {
    id: FORM_FIELD_KEYS.firstUseAnywhereYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
    defaultInvalidMessage: "Please provide Year",
    validate: validateYear,
  },
  [FORM_FIELD_KEYS.firstUseInCommerceMonth]: {
    id: FORM_FIELD_KEYS.firstUseInCommerceMonth,
    required: true,
    label: "First Use in Commerce",
    placeholder: "Month",
    defaultInvalidMessage: "Please provide Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstUseInCommerceDay]: {
    id: FORM_FIELD_KEYS.firstUseInCommerceDay,
    required: true,
    emptyLabel: true,
    placeholder: "Day",
    defaultInvalidMessage: "Please provide Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.firstUseInCommerceYear]: {
    id: FORM_FIELD_KEYS.firstUseInCommerceYear,
    required: true,
    emptyLabel: true,
    placeholder: "Year",
    defaultInvalidMessage: "Please provide Year",
    validate: validateYear,
  },
  [FORM_FIELD_KEYS.individualOrgOrInd]: {
    id: FORM_FIELD_KEYS.individualOrgOrInd,
    required: false,
    options: [
      {
        label: "Individual",
        value: "Individual",
      },
      {
        label: "Organization",
        value: "Organization",
      },
    ],
  },
  [FORM_FIELD_KEYS.address]: {
    id: FORM_FIELD_KEYS.address,
    required: true,
    placeholder: "Address",
    maxLength: "30",
    validate: validateAddress1,
  },
  [FORM_FIELD_KEYS.city]: {
    id: FORM_FIELD_KEYS.city,
    required: true,
    placeholder: "City",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide a city name",
    validate: validateCity,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.zip]: {
    id: FORM_FIELD_KEYS.zip,
    required: true,
    placeholder: "ZIP / Postal Code",
    pattern: "\\d{5}",
    maxLength: "5",
    defaultInvalidMessage: "Please provide a zip code",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.state]: {
    id: FORM_FIELD_KEYS.state,
    required: true,
    placeholder: "State/Province",
    defaultInvalidMessage: "Select a State/Province",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.phone]: {
    id: FORM_FIELD_KEYS.phone,
    label: "Phone Number",
    required: true,
    type: "phone",
    mask: "(999) 999-9999",
    placeholder: "(123) 456-7890",
    pattern: ".{14,}",
    maxLength: "14",
    defaultInvalidMessage: "You must provide a valid phone number",
  },
  [FORM_FIELD_KEYS.email]: {
    id: FORM_FIELD_KEYS.email,
    label: "Email address",
    required: true,
    type: "email",
    pattern:
      "^([a-zA-Z0-9_\\-\\+\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$",
    maxLength: "30",
    defaultInvalidMessage: "You must provide a valid email address",
  },
  [FORM_FIELD_KEYS.isNonUsBased]: {
    id: FORM_FIELD_KEYS.isNonUsBased,
    options: [
      {
        label: "US Based",
        value: "USBased",
      },
      {
        label: "Non US Based",
        value: "NonUSBased",
      },
    ],
  },
  [FORM_FIELD_KEYS.orgName]: {
    id: FORM_FIELD_KEYS.orgName,
    required: true,
    label: "Organization Name",
    placeholder: "Organization Name",
    maxLength: "30",
    pattern: "^[ a-zA-Z0-9\\/\\-]+$",
    onKeyPress: (event) => {
      if (!/[ a-zA-Z0-9/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.orgType]: {
    id: FORM_FIELD_KEYS.orgType,
    required: true,
    label: "Organization Type",
    placeholder: "Organization Type",
    options: ORG_TYPE_OPTIONS,
  },
  [FORM_FIELD_KEYS.otherOrgType]: {
    id: FORM_FIELD_KEYS.otherOrgType,
    placeholder: "Specify Type",
    required: true,
    options: OTHER_ORG_TYPE_OPTIONS,
  },
  [FORM_FIELD_KEYS.orgPosition]: {
    id: FORM_FIELD_KEYS.orgPosition,
    required: true,
    label: "Position",
    placeholder: "CEO",
    maxLength: "30",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.orgState]: {
    id: FORM_FIELD_KEYS.orgState,
    required: true,
    label: "State of Formation",
    placeholder: "State of Formation",
    defaultInvalidMessage: "Please provide the State of Formation",
    options: STATE_OPTIONS,
  },
  [FORM_FIELD_KEYS.formationCountry]: {
    id: FORM_FIELD_KEYS.formationCountry,
    required: true,
    disabled: true,
    label: "Country of Formation",
    placeholder: "Country of Formation",
    pattern: "^[ a-zA-Z\\/\\-]+$",
    maxLength: "30",
    defaultInvalidMessage: "Please provide Country of Formation",
    onKeyPress: (event) => {
      if (!/[ a-zA-Z/-]/.test(event.key)) event.preventDefault();
    },
  },
  [FORM_FIELD_KEYS.goods]: {
    id: FORM_FIELD_KEYS.goods,
    required: false,
    options: [
      {
        label: "Goods",
        value: "true",
      },
      {
        label: "Services",
        value: "false",
      },
    ],
  },
  [FORM_FIELD_KEYS.goodsCategory]: {
    id: FORM_FIELD_KEYS.goodsCategory,
    required: true,
    placeholder: "Start typing to search categories…",
  },
  [FORM_FIELD_KEYS.description]: {
    id: FORM_FIELD_KEYS.description,
    required: true,
    placeholder: "Enter additional description",
    maxLength: "90",
    defaultInvalidMessage: "Please provide additional description",
  },
  [FORM_FIELD_KEYS.dateOfBirthMonth]: {
    id: FORM_FIELD_KEYS.dateOfBirthMonth,
    required: true,
    placeholder: "Month",
    defaultInvalidMessage: "Please select Month",
    options: MONTH_OPTIONS,
  },
  [FORM_FIELD_KEYS.dateOfBirthDay]: {
    id: FORM_FIELD_KEYS.dateOfBirthDay,
    required: true,
    placeholder: "Day",
    defaultInvalidMessage: "Please select Day",
    options: DAY_OPTIONS,
  },
  [FORM_FIELD_KEYS.dateOfBirthYear]: {
    id: FORM_FIELD_KEYS.dateOfBirthYear,
    required: true,
    placeholder: "Year",
    defaultInvalidMessage: "Please select Year",
  },
  [FORM_FIELD_KEYS.customerIdFile]: {
    id: FORM_FIELD_KEYS.customerIdFile,
    required: true,
  },
  [FORM_FIELD_KEYS.driverLicenceOrID]: {
    id: FORM_FIELD_KEYS.driverLicenceOrID,
    label: "Driver's License or ID Number",
    required: true,
    defaultInvalidMessage: "Please provide Driver's License or ID Number",
    validate: validateDriverLicenceOrID,
  },
};

export const getFieldProps = (fieldKey) => {
  return fieldProps[fieldKey] || {};
};

export const getDayOptions = ({ year, month }) => {
  const daysInMonth = getDaysInMonth(new Date(parseInt(year || +new Date().getFullYear()), parseInt(month) - 1));
  return Array.from({ length: isNaN(daysInMonth) ? 0 : daysInMonth }, (_, i) => {
    return { value: `${i + 1}`, title: `${i + 1}` };
  });
};
