import React, { useMemo } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { isMobile } from "react-device-detect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { hexToRGBA } from "../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledProductCard = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #001e5a;
  border-radius: 12px;
  height: 100%;
  width: 100%;

  padding-top: ${({ popular }) => popular ? '30px' : '20px'};
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;

  position: relative;

  &:hover {
    background-color: #00009a;
  }

  :after {
    position: absolute;
    text-align: left;
    top: -1px;
    right: -1px;
    display: ${({ popular }) => popular ? 'block' : 'none'};
    padding: 2px 14px;
    color: #fff;
    font-family: 'Arial';
    font-size: 13px;
    line-height: calc(1.4rem * (12 / 16));
    background-color: red;
    border-top-right-radius: 12px;
    content: "MOST POPULAR";
  }

  .product-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    .product-card-title {
      display: flex;
      justify-content: center;

      font-family: 'Arial';
      font-weight: bold;
      font-size: 21px;
      line-height: calc(1.4rem * (22 / 16));
      color: white;
    }
    
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;

    :after {
      width: 100%;
      left: -0.2px;
      text-align: center;
      border-top-left-radius: 12px;
    }
  }
`;

function ProductCard({ id, iconSrc, title, popular }) {

  return (
      <StyledProductCard tabIndex={0} popular={popular}>
        <div className="product-card-content">
          <div className="product-card-title">
            <span>{title}</span>
          </div>
        </div>
      </StyledProductCard>
  );
}

export default ProductCard;
